import React, { useEffect } from "react"

import Layout from "../components/layout"
import Nav from "../components/navigation"
import SEO from "../components/seo"
import VideoContainer from "../components/videocontainer"

import loopWhite from "../video/looping-white.mp4"

const LoopWhite = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#FFF";
  }, [])
  return (
    <Layout className="whiteLayout">
      <SEO title="Pintu Logo Animation | White Loop" />
      <Nav />
      <div className="videoWrapper">
      <VideoContainer src={loopWhite} />
      </div>
    </Layout>
  )
}

export default LoopWhite
